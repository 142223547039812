import React from "react"
import BannerImage from "gatsby-background-image"
import { Link } from "gatsby"
import SVGIcon from "../Global/SVGIcon"

const ScrollToLink = () => (
  <Link to="/#about-gh">
    <SVGIcon
      name="scroll"
      width={40}
      id="scroll-icon"
      style={{ position: "absolute", bottom: "8rem" }}
    />
  </Link>
)

export default function BannerSection({ img, styleClass, title, children }) {
  return (
    <BannerImage className={styleClass} fluid={img}>
      <div className={title === "We build ideas." ? "text-center" : ""}>
        <h1>{title}</h1>
        <p>{children}</p>
        {title === "We build ideas." && <ScrollToLink />}
      </div>
    </BannerImage>
  )
}

BannerSection.defaultProps = {
  title: "default title",
  styleClass: "subpage-banner",
}
